import React, { useEffect, useState } from "react";
import "./Hisaab.css";
import Button from "../../Components/Buttons/Button";
import { DatePicker } from "antd";
import DynamicTable from "../../Components/Table/DynamicTable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getAllVouchers, getVoucherByDate, getVoucherById } from "../../services/VoucherServices";
import { getUserById } from "../../services/UserServices";

const Hisaab = () => {
  const [userID, setUserID] = useState(null);
  const [balance, setBalance] = useState("");
  const [cbalance, setCBalance] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const onStartDateChange = (date, dateString) => {
    setStartDate(formatDate(date));
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(formatDate(date));
  };

  const getVoucherOnLOad = async()=>{
    const response = await getAllVouchers();
    const newData = response.map((data, index) => ({
      key: index.toString(),
      num: index + 1,
      user_id: data.user_id,
      name: data.user.user_name,
      date: formatDate(data.createdAt),
      desc: data.description,
      debit: data.voucher_type!="receipt"?data.amount:0,
      credit: data.voucher_type=="receipt"?data.amount:0,
      // balance: data.voucher_type=="receipt"? `+${data.amount}`:`-${data.amount}`,
      balance: data.user.balance,
    }));
    setFilteredData(newData);
  }

  useEffect(()=>{
    getVoucherOnLOad()
  },[])

  const handleView = async () => {
    setFilteredData([])
    if (startDate && endDate) {
      // const uresponse = await getUserById(userID);
      // setBalance(uresponse.balance?uresponse.balance:0)
      const response = await getVoucherByDate(startDate,endDate,userID?userID:0);
      setBalance(response.draws[0].user.balance)
      setCBalance(parseInt(response.draws[0].balance) )
      const newData = response.draws.map((data, index) => ({
        key: index.toString(),
        num: index + 1,
        user_id: data.user_id,
        name: data.user.user_name,
        date: formatDate(data.createdAt),
        desc: data.description,
        debit: data.voucher_type!="receipt"?data.amount:0,
        credit: data.voucher_type=="receipt"?data.amount:0,
        // balance: data.voucher_type=="receipt"? `+${data.amount}`:`-${data.amount}`,
        balance: data.balance,
      }));
      setFilteredData(newData);
    }
    else if(userID){
      const response = await getVoucherById(userID);
      const newData = response.map((data, index) => ({
        key: index.toString(),
        num: index + 1,
        user_id: data.user_id,
        date: formatDate(data.createdAt),
        desc: data.description,
        debit: data.voucher_type!="receipt"?data.amount:0,
        credit: data.voucher_type=="receipt"?data.amount:0,
        balance: data.voucher_type=="receipt"? `+${data.amount}`:`-${data.amount}`,
      }));
      setFilteredData(newData);    
    }
    }

    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const data = filteredData.map((item,index) => {
      const num = index + 1;
      return {
        key: num.toString(),
        num,
        name: item.name,
        user_id: item.user_id.toString(),
        date: formatDate(item.date),
        desc: item.desc,
        debit: item.debit,
        credit: item.credit,
        balance: item.balance
      };
    });

  const downloadPdf = (combinations) => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Format data for the table
    const tableColumns = [
      "#",
      "User Id",
      "Name",
      "Date",
      "Description",
      "Debit",
      "Credit",
      "Balance",
    ];
    // Format data for the table
    const tableData = data.map((item) => [
      item.num.toString(),
      item.user_id,
      item.name,
      item.date,
      item.desc,
      item.debit.toString(),
      item.credit.toString(),
      item.balance.toString(),
    ]);

    // Add table to document
    doc.autoTable(tableColumns, tableData, { startY: 20 });

    // Save the PDF
    doc.save("hisaab-report.pdf");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "num",
    },
    {
      title: "User Id",
      dataIndex: "user_id",
    },
    {
      title: "User Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Description",
      dataIndex: "desc",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
  ];

  return (
    <div className="hisaab-container">
      <div className="date-name-div">
        <span className="date-lable">User Id:</span>
        <input
          placeholder="User Id"
          value={userID}
          onChange={(e) => setUserID(e.currentTarget.value)}
          style={{
            width: "20%",
            height: 35,
            borderRadius: 10,
            paddingLeft: 10,
            border: "1px solid gray",
            fontFamily: "sans-serif",
          }}
        />
      </div>
      <div className="hisaab-upper-div">
        <div className="date-div">
          {" "}
          <div className="date-name-div">
            <span className="date-lable">Start Date:</span>
            <DatePicker
              onChange={onStartDateChange}
              style={{
                width: "60%",
                height: 35,
                fontFamily: "sans-serif",
              }}
            />
          </div>
          <div className="date-name-div">
            <span className="date-lable">End Date:</span>
            <DatePicker
              onChange={onEndDateChange}
              style={{
                width: "60%",
                height: 35,
                fontFamily: "sans-serif",
              }}
            />
          </div>
        </div>
        <div className="button-div">
          {" "}
          <Button
            label={"View"}
            className="hisaab-button"
            onClick={handleView}
          />
          <Button
            label={"Print"}
            className="hisaab-button"
            onClick={downloadPdf}
          />
        </div>
      </div>
      <div className="hisaab-lower-div">
        <span className="hissab-heading">Hisaab</span>
        <span className="balance-span">Opening Balance: {balance}</span>
        <div className="hisaab-table-div">
          <DynamicTable
            data={filteredData}
            columns={columns}
            pagination={filteredData.length > 10}
            pageSize={10}
          />
        </div>
        <span className="balance-span">Closing Balance: {cbalance}</span>
      </div>
    </div>
  );
};

export default Hisaab;
