import React, { useEffect, useState } from "react";
import "./Layouts.css";
// import confirm from "antd/es/modal/confirm";
// import { ExclamationCircleFilled } from "@ant-design/icons";
import { Drawer, Layout } from "antd";
import Navbar from "../Header/Navbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import { Base_Route } from "../../helper/constant";

const { Header, Sider, Content } = Layout;

const Layouts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedButton, setSelectedButton] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  // ... existing code ...

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const role = localStorage.getItem("Role");
  console.log("on Layout Page", role);
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/portal/book")) {
      setSelectedButton("1");
    } else if (path.includes("/portal/hisaab")) {
      setSelectedButton("2");
    } 
    else if (path.includes("/portal/voucher")) {
      setSelectedButton("3");
    } 
    else if (path.includes("/portal/allVoucher")) {
      setSelectedButton("4");
    } 
    // else if (path.includes("/portal/user")) {
    //   setSelectedButton("5");
    // }
    else if (path.includes("/portal/alluser")) {
      setSelectedButton("6");
    }
    else if (path.includes("/portal/alldraw")) {
      setSelectedButton("7");
    }
     else if (path.includes("/portal/password")) {
      setSelectedButton("8");
    }
  }, [location]);

  const navigateRouteHandler = (e) => {
    setSelectedButton(e);
    if (e === "1") {
      setDrawerVisible(false);
      navigate("/portal/book");
    } else if (e === "2") {
      navigate("/portal/hisaab");
      setDrawerVisible(false);
    } 
    else if (e === "3") {
      navigate("/portal/voucher");
      setDrawerVisible(false);
    } 
    else if (e === "4") {
      navigate("/portal/allVoucher");
      setDrawerVisible(false);
    } 
    else if (e === "5") {
      navigate("/portal/user");
      setDrawerVisible(false);
    } 
    else if (e === "6") {
      navigate("/portal/alluser");
      setDrawerVisible(false);
    } 
    else if (e === "7") {
      navigate("/portal/alldraw");
      setDrawerVisible(false);
    } 
    else if (e === "8") {
      navigate("/portal/password");
      setDrawerVisible(false);
    } 
    else {
      mylogout();
      setDrawerVisible(false);
    }
  };

  const mylogout = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("Role");
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <Layout style={{ height: "100%" }}>
        <Sider
          style={{
            background: "white",
            height: "100vh",
            position: "fixed",
            left: 0,
            // borderRight: "2px solid white",
          }}
        >
          <div className="sideBar">
            <Drawer
              placement="left"
              closable={false}
              onClose={onCloseDrawer}
              width={200}
              open={drawerVisible}
              className="drawerSider"
            >
              <div className="sidebar-lower-div">
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "1" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("1")}
                >
                  Book
                </button>
                {role === "admin" && (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "2" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("2")}
                  >
                    {" "}
                    Hisaab
                  </button>
                )}
                {/* {role === "admin" && (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "3" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("3")}
                  >
                    Vouchers
                  </button>
                )} */}
                {role === "admin" && (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "4" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("4")}
                  >
                    Vouchers
                  </button>
                )}
                {/* {role === "admin" && (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "5" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("5")}
                  >
                    Add User
                  </button>
                )}  */}
                {role === "admin" && (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "6" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("6")}
                  >
                    User
                  </button>
                )} 
                {role === "admin" ? (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "7" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("7")}
                  >
                    Draw
                  </button>
                ) : 
                (
                  <button
                    // className="sider-btn"
                    className={`sider-btn ${
                      selectedButton === "8" ? "clicked" : ""
                    }`}
                    onClick={() => navigateRouteHandler("8")}
                  >
                    Change Password
                  </button>
                )}
                <div className="logout-div">
                  <button
                    // className="sider-btn"
                    onClick={() => navigateRouteHandler("8")}
                    className={`sider-btn ${
                      selectedButton === "9" ? "clicked" : ""
                    }`}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </Drawer>
            <div className="sidebar-upper-div">Dealers</div>
            <div className="sidebar-lower-div">
              <button
                // className="sider-btn"
                className={`sider-btn ${
                  selectedButton === "1" ? "clicked" : ""
                }`}
                onClick={() => navigateRouteHandler("1")}
              >
                Book
              </button>
              {role === "admin" && (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "2" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("2")}
                >
                  {" "}
                  Hisaab
                </button>
              )}
              {/* {role === "admin" && (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "3" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("3")}
                >
                  Vouchers
                </button>
              )} */}
              {role === "admin" && (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "4" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("4")}
                >
                  Vouchers
                </button>
              )}
              {/* {role === "admin" && (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "5" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("5")}
                >
                  Add User
                </button>
              )} */}
              {role === "admin" && (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "6" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("6")}
                >
                  User
                </button>
              )}
              {role === "admin" ? (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "7" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("7")}
                >
                  Draw
                </button>
              ) : (
                <button
                  // className="sider-btn"
                  className={`sider-btn ${
                    selectedButton === "8" ? "clicked" : ""
                  }`}
                  onClick={() => navigateRouteHandler("8")}
                >
                  Change Password
                </button>
              )}
              <div className="logout-div">
                <button
                  // className="sider-btn"
                  onClick={() => navigateRouteHandler("9")}
                  className={`sider-btn ${
                    selectedButton === "9" ? "clicked" : ""
                  }`}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: "white",
              position: "fixed",
              zIndex: 1,
              right: 0,
              left: 200,
              // borderBottom: "2px solid #6f2727",
            }}
          >
            <Navbar onOpenDrawer={showDrawer} />
          </Header>
          <Content
            style={{
              margin: " 75px 10px 0px 210px",
              minHeight: "calc(100vh - 75px)",
              height: "100%",
            }}
          >
            <Outlet />{" "}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Layouts;
