import axios from 'axios';
import { BASE_URL } from '../Config/Constants';
import { toast } from 'react-toastify';

export const getDraw = async (data) => {
  try {
    const response = await axios.get(`${BASE_URL}/draw/get`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const getDrawById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/draw/getbyid/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const getWinnersById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/draw/getwinner/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const updateDraw = async (data,id) => {
  try {
      const response = await axios.post(`${BASE_URL}/draw/update/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

export const deleteDraw = async (id) => {
  try {
      const response = await axios.get(`${BASE_URL}/draw/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};

export const createDraw = async (data) => {
  try {
      const response = await axios.post(`${BASE_URL}/draw/create`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const generateCombinations = async (data) => {
  console.log("the value receives inside services functipon", data);
  const number = data.number;
  const digitLength = data.digitLength;
  const fValue = data.fValue;
  const sValue = data.sValue;
  console.log("new number", number, "and digit length is", digitLength);
  try {
    const response = await axios.post(`${BASE_URL}/combination/generate`, { number, digitLength,fValue,sValue });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const saveCombinations = async (userId,drawId,data) => {
    const combinations = data;
  try {
    const response = await axios.post(`${BASE_URL}/combination/save`,{combinations,drawId,userId});
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const getDrawInfo = async () => {
  try {
      const token = localStorage.getItem('Token'); // Ensure this token is correct and valid
    const response = await axios.get(`${BASE_URL}/join/info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching draw information:', error);
    throw error;
  }
};

export const getAllDraws = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/draw/getall`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

