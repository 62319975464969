import React, { useEffect, useState } from "react";
import { deleteDraw, getAllDraws, getWinnersById } from "../../services/DrawServices";
import "./DrawList.css";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import 'jspdf-autotable';

function DrawList() {
  const navigate = useNavigate();
  const [drawList, setdrawList] = useState([]);
  useEffect(() => {
    getAllDraws().then((data) => {
      setdrawList(data);
    });
  }, []);

  const deleteSetting = async (id) => {
    try {
      const result = await deleteDraw(id);
      alert(result.message);
      await getAllDraws();
    } catch (error) {
      alert(error);
    }
  };

  const doc = new jsPDF();

  const generatePDF =async (id) => {
    let data = await getWinnersById(id)
    console.log(data)
    const tableData = [
      ['Username', 'Number', 'F', 'S', 'winning amount']
    ];

    let winnersList = []

    for (let i = 0; i<data.winners.length;i++){
      let index = []
      index.push(data.winners[i].username)
      index.push(data.winners[i].number)
      index.push(data.winners[i].f)
      index.push(data.winners[i].s)
      index.push(data.winners[i].amount)
      winnersList.push(index)
    }

    // Add headers and rows to the PDF table
    doc.autoTable({
      head: [tableData[0]],
      body: winnersList
    });

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 10,
      head: [['Total Winning Amount']],
      body: [[data.total]]
    });


    const winingNumberData = [
      ['F', data.draw.f_field],
      ['S1', data.draw.s1_field],
      ['S2', data.draw.s2_field],
      ['S3', data.draw.s3_field]
    ];

    console.log(winingNumberData)

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 30,
      head: [['Winning Number','']],
      body: winingNumberData
    });

    const prizeData = [
      ['1 digit number', data.draw.oneDigitF,data.draw.oneDigitS],
      ['2 digit number', data.draw.twoDigitF,data.draw.twoDigitS],
      ['3 digit number', data.draw.threeDigitF,data.draw.threeDigitS],
      ['4 digit number', data.draw.fourDigitF,data.draw.fourDigitS]
    ];

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 30,
      head: [['Prize Payout','','']],
      body: prizeData
    });

     // Save the PDF
     doc.save("winners.pdf");
  };

  return (
    <>
      <div className="listcontainer">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h2>List of Draws</h2>
          <a href="/portal/addDraw" className="btn-add">
            Add
          </a>
        </div>
        <div className="draw">
          <h4>Draw Id</h4>
          <h4>Name</h4>
          <h4>User ID</h4>
          <h4>Edit</h4>
          <h4>Delete</h4>
          <h4>Report</h4>
        </div>

        <hr />

        {drawList.length > 0 &&
          drawList.map((draw, idx) => (
            <div key={idx} className="draw">
              <h4>{draw.id}</h4>
              <h4>{draw.draw_name}</h4>
              <h4>{draw.user_id}</h4>
              <button
                onClick={() =>
                  navigate("/portal/addDraw", { state: draw.user_id })
                }
                className="btn-edit"
              >
                Edit
              </button>
              <button onClick={() => deleteSetting(draw.id)} className="btn">
                Delete
              </button>
              <button onClick={()=>generatePDF(draw.id)} className="btn-report">
                Download
              </button>
            </div>
          ))}
      </div>
    </>
  );
}

export default DrawList;
