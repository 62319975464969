import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Auth/Login/Login";
import Layouts from "./Components/Layouts/Layouts";
import Book from "./Pages/Book/Book";
import Hisaab from "./Pages/Hisaab/Hisaab";
import Voucher from "./Pages/Voucher/Voucher";
import AddUser from "./Pages/addUser/AddUser";
import ChangePassword from "./Pages/Auth/ChangePassword/ChangePassword";
import ProtectedRoute from "./Components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import AddVoucher from "./Pages/addVoucher/addVoucher";
import AddDraw from "./Pages/addDraw/AddDraw";
import UsersList from "./Pages/UserList/UsersList";
import DrawList from "./Pages/DrawList/DrawList";
import VoucherList from "./Pages/VoucherList/VoucherList";

function App() {
  return (
    <div>
      {/* <AuthProvider> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/portal/*" element={<Layouts />}>
              <Route
                path="book"
                element={
                    <Book />
                }
              />
              <Route
                path="hisaab"
                element={
                    <Hisaab />
                }
              />
              <Route
                path="voucher"
                element={
                    <Voucher />
                }
              />
              <Route
                path="user"
                element={
                    <AddUser />
                }
              />
              <Route
                path="alluser"
                element={
                    <UsersList />
                }
              />
              <Route
                path="addVoucher"
                element={
                    <AddVoucher />
                }
              />
              <Route
                path="allVoucher"
                element={
                    <VoucherList />
                }
              />
              <Route
                path="addDraw"
                element={
                    <AddDraw />
                }
              />
              <Route
                path="alldraw"
                element={
                    <DrawList />
                }
              />
              <Route
                path="password"
                element={
                    <ChangePassword />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      {/* </AuthProvider> */}
    </div>
  );
}

export default App;
