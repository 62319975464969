import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { adminLogin } from "../../../services/AdminServices";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const myLogin = async () => {
    try {
      console.log(formValues)
      const result = await adminLogin(formValues);
      if (result) {
        localStorage.setItem("userId", result.user_id);
        localStorage.setItem("Token", result.token);
        localStorage.setItem("Role", result.role);
        // toast.success(result.data.message);
        // Navigate with state
        navigate("/portal/book", {
          state: {
            role: result.role, // Assuming this is the data you want to pass
          },
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.warn("Invalid Credentials");
      } else {
        toast.warn(error.message);
      }
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    myLogin();
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <form className="login-form" onSubmit={handleSubmit}>
        <h1 className="login-title">Login</h1>
        <div className="login-form-group">
          <label className="form-lable" htmlFor="userName">
            Username :
          </label>
          <Input
            type="text"
            className="login-form-input"
            id="email"
            name="email"
            defaultValue={formValues.email}
            onChange={handleChange}
            placeholder="Username"
            required
          ></Input>
        </div>
        <div className="login-form-group">
          <label className="form-lable" htmlFor="password">
            Password :
          </label>
          <Input
            type={passwordShown ? "text" : "password"}
            className="login-form-input"
            id="password"
            name="password"
            defaultValue={formValues.password}
            onChange={handleChange}
            placeholder="Password"
            required
            suffix={
              passwordShown ? (
                <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
              ) : (
                <EyeOutlined onClick={togglePasswordVisibility} />
              )
            }
          ></Input>
        </div>
        <button className="login-btn" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
