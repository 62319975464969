import axios from "axios";
import { BASE_URL } from "../Config/Constants";

export const getVoucher = async (data) => {
  try {
    const response = await axios.get(`${BASE_URL}/draw/get`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const createVoucher = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/voucher/create`, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const updateVoucher = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/voucher/update`, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getAllVouchers = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/voucher/getall`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getVoucherById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/voucher/get/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getOneVoucherById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/voucher/getone/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const deleteVoucherById = async (id,uid) => {
  try {
    const response = await axios.get(`${BASE_URL}/voucher/delete/${id}/${uid}`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getVoucherByDate = async (start,end,id) => {
  try {
    const response = await axios.get(`${BASE_URL}/voucher/getbydate/${start}/${end}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
