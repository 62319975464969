import React, { useEffect, useState } from "react";
import "./AddUser.css";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input } from "antd";
// import { toast } from "react-toastify";
import { getIdUserNew, getUserById, updateRegister, userRegister } from "../../services/UserServices";
import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  // const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    oneDigitMinF: "",
    oneDigitMaxF: "",
    twoDigitMinF: "",
    twoDigitMaxF: "",
    threeDigitMinF: "",
    threeDigitMaxF: "",
    fourDigitMinF: "",
    fourDigitMaxF: "",
    oneDigitMinS: "",
    oneDigitMaxS: "",
    twoDigitMinS: "",
    twoDigitMaxS: "",
    threeDigitMinS: "",
    threeDigitMaxS: "",
    fourDigitMinS: "",
    fourDigitMaxS: "",
    user_id: "",
    role:"user",
    user_name: "",
    password: "",
    email: "",
    mobile: "",
    commission: "",
    balance: "",
    language: "eng",
  });
  const location = useLocation(); 

  useEffect(()=>{
    console.log(location.state)
    if(!location.state){
      getIdUserNew().then((data)=>{
        setFormValues({ ...formValues, user_id: data.userId });
      })
    }
    else{
      handleGetUserById(location.state)
    }
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const saveSetting = async () => {
    try {
      const result = await userRegister(formValues);
      if (result) {
        alert(result.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSetting = async () => {
    try {
      const result = await updateRegister(formValues);
      if (result) {
        alert(result.message)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetUserById = async (user_id)=>{
    const response = await getUserById(user_id);
    setFormValues({
      oneDigitMinF: response.oneDigitMinF,
      oneDigitMaxF: response.oneDigitMaxF,
      twoDigitMinF: response.twoDigitMinF,
      twoDigitMaxF: response.twoDigitMaxF,
      threeDigitMinF: response.threeDigitMinF,
      threeDigitMaxF: response.threeDigitMaxF,
      fourDigitMinF: response.fourDigitMinF,
      fourDigitMaxF: response.fourDigitMaxF,
      oneDigitMinS: response.oneDigitMinS,
      oneDigitMaxS: response.oneDigitMaxS,
      twoDigitMinS: response.twoDigitMinS,
      twoDigitMaxS: response.twoDigitMaxS,
      threeDigitMinS: response.threeDigitMinS,
      threeDigitMaxS: response.threeDigitMaxS,
      fourDigitMinS: response.threeDigitMaxS,
      fourDigitMaxS: response.fourDigitMaxS,
      user_id: response.user_id,
      user_name: response.user_name,
      email: response.email,
      mobile: response.phoneNumb,
      commission: response.commission,
      balance: response.balance,
      language: response.language,
    })
  }

  const togglePasswordVisibility = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Check if any field is empty
    const isEmptyField = Object.values(formValues).some((value) => value === "");

    if (isEmptyField) {
      alert("Please fill in all the fields");
    } else {
      saveSetting();
    }
  };
  return (
    <div className="user-container">
      <div className="heading-div">
        <h3 className="featureTitle">
        <b>{!location.state? 'Add New User' : 'Edit User' }</b>
        </h3>
      </div>
      <div className="form-main">
        <div className="form-left">
          {" "}
          <div className="form-group">
            <label className="my-label" htmlFor="userId">
              User Id :
            </label>
            <Input
              type="text"
              className="form-control"
              id="userId"
              name="user_id"
              value={formValues.user_id}
              onChange={handleChange}
              placeholder="User_Id"
              // onPressEnter={handleGetUserById}
              required
              disabled
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="userName">
              User Name :
            </label>
            <Input
              type="text"
              className="form-control"
              id="userName"
              name="user_name"
              value={formValues.user_name}
              onChange={handleChange}
              placeholder="Username"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="password">
              Password :
            </label>
            <Input
              type={passwordShown ? "text" : "password"}
              className="form-control"
              id="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              placeholder="Password"
              required
              suffix={
                passwordShown ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="email">
              Email :
            </label>
            <Input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              placeholder="Email"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="mobile">
              Mobile # :
            </label>
            <Input
              type="text"
              className="form-control"
              id="mobile"
              name="mobile"
              value={formValues.mobile}
              onChange={handleChange}
              placeholder="Mobile Number"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="commission">
              Commission % :
            </label>
            <Input
              type="number"
              className="form-control"
              id="commission"
              name="commission"
              value={formValues.commission}
              onChange={handleChange}
              placeholder="Commission"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="balance">
              Balance :
            </label>
            <Input
              type="text"
              className="form-control"
              id="balance"
              name="balance"
              value={formValues.balance}
              onChange={handleChange}
              placeholder="Balance"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="language">
              Language :
            </label>
            <select
              className="form-control"
              id="language"
              name="language"
              defaultValue={formValues.language}
              onChange={handleChange}
              // contentEditable
            >
              <option value="eng">English</option>
              <option value="urdu">Urdu</option>
              <option value="hindi">Hindi</option>
            </select>
          </div>
        </div>
        <div className="form-right">
          <div className="number-form">
            <div className="digit-title-div">
              <span className="digit-title"> One Digit Number :</span>
              <span className="digit-title"> Two Digit Number :</span>
              <span className="digit-title"> Three Digit Number :</span>
              <span className="digit-title"> Four Digit Number :</span>
            </div>
            <div className="limit-group">
              <div className="header">First (F)</div>
              <div className="min-max">
                <div className="min">
                  <label>Min. Limit</label>
                  <Input
                    type="number"
                    name="oneDigitMinF"
                    value={formValues.oneDigitMinF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <label>Max. Limit</label>
                  <Input
                    type="number"
                    name="oneDigitMaxF"
                    value={formValues.oneDigitMaxF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="twoDigitMinF"
                    value={formValues.twoDigitMinF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <Input
                    type="number"
                    name="twoDigitMaxF"
                    value={formValues.twoDigitMaxF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="threeDigitMinF"
                    value={formValues.threeDigitMinF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <Input
                    type="number"
                    name="threeDigitMaxF"
                    value={formValues.threeDigitMaxF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="fourDigitMinF"
                    value={formValues.fourDigitMinF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <Input
                    type="number"
                    name="fourDigitMaxF"
                    value={formValues.fourDigitMaxF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
            </div>
            <div className="limit-group">
              <div className="header">Second (S)</div>
              <div className="min-max">
                <div className="min">
                  <label>Min. Limit</label>
                  <Input
                    type="number"
                    name="oneDigitMinS"
                    value={formValues.oneDigitMinS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <label>Max. Limit</label>
                  <Input
                    type="number"
                    name="oneDigitMaxS"
                    value={formValues.oneDigitMaxS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="twoDigitMinS"
                    value={formValues.twoDigitMinS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <Input
                    type="number"
                    name="twoDigitMaxS"
                    value={formValues.twoDigitMaxS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="threeDigitMinS"
                    value={formValues.threeDigitMinS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <Input
                    type="number"
                    name="threeDigitMaxS"
                    value={formValues.threeDigitMaxS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="fourDigitMinS"
                    value={formValues.fourDigitMinS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
                <div className="max">
                  <Input
                    type="number"
                    name="fourDigitMaxS"
                    value={formValues.fourDigitMaxS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{display:'flex'}}>
        {
          !location.state ? (
          <div className="form-btn-div">
            <button className="save-btn" onClick={handleSubmit}>
              Save
            </button>
          </div>) : (
          <div className="form-btn-div">
            <button className="save-btn" onClick={updateSetting} >
              Update
            </button>
          </div>)
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
