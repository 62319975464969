import React, { useEffect, useState } from "react";
import "./ChangePassword.css";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Input, Modal } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { userChangePassword } from "../../../services/UserServices";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    const userid = localStorage.getItem("userId");
    console.log("user id inside useEffect is", userid);
    setUserId(userid);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const saveSetting = async () => {
    console.log("userr====>", formValues);
    const result = await userChangePassword(formValues, userId);
    console.log("The results got from admin Login api", result);
    if (result) {
      navigate("/portal/book");
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveSetting();
  };

  return (
    <div className="password-container">
      <div className="title-div">
        <span className="title-pwd">Change Password</span>
      </div>
      <div className="password-form-div">
        <form className="form-main" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="my-label" htmlFor="currentPassword">
              Current Password :
            </label>
            <Input
              type={passwordShown ? "text" : "password"}
              className="form-input"
              id="currentPassword"
              name="currentPassword"
              defaultValue={formValues.currentPassword}
              onChange={handleChange}
              placeholder="Current Password"
              required
              suffix={
                passwordShown ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="newPassword">
              New Password :
            </label>
            <Input
              type={passwordShown ? "text" : "password"}
              className="form-input"
              id="newPassword"
              name="newPassword"
              defaultValue={formValues.newPassword}
              onChange={handleChange}
              placeholder="New Password"
              required
              suffix={
                passwordShown ? (
                  <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                ) : (
                  <EyeOutlined onClick={togglePasswordVisibility} />
                )
              }
            ></Input>
          </div>
          <div className="form-btn-div">
            <button className="save-btn" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
