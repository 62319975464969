import React, { useEffect, useState } from "react";
import "../addVoucher/addVoucher.css";
import { Input } from "antd";
// import { toast } from "react-toastify";
import {
  createDraw,
  getDrawById,
  updateDraw,
} from "../../services/DrawServices";
import { useLocation, useNavigate } from "react-router-dom";

const AddDraw = () => {
  const location = useLocation();
  // console.log('state recieval', location.state)
  const [formValues, setFormValues] = useState({
    user_id: "",
    draw_name: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    f_field: "",
    s1_field: "",
    s2_field: "",
    s3_field: "",
    oneDigitF: "",
    oneDigitS: "",
    twoDigitF: "",
    twoDigitS: "",
    threeDigitF: "",
    threeDigitS: "",
    fourDigitF: "",
    fourDigitS: "",
  });

  const [id, setId] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const saveSetting = async () => {
    try {
      const result = await createDraw(formValues);
      console.log(result);
      if (result.id) {
        alert("Sucessfully added");
      }
    } catch (error) {
      alert(error);
    }
  };

  const navigate = useNavigate();

  const updateSetting = async () => {
    try {
      const result = await updateDraw(formValues, id);
      alert(result.message);
      navigate("/portal/alldraw");
    } catch (error) {
      alert(error);
    }
  };

  const getDrawId = async (user_id) => {
    try {
      const result = await getDrawById(user_id);
      // console.log(result);
      setId(result.id);
      setFormValues({
        user_id: result.user_id,
        draw_name: result.draw_name,
        start_date: result.start_date,
        start_time: result.start_time,
        end_date: result.end_date,
        end_time: result.end_time,
        f_field: result.f_field,
        s1_field: result.s1_field,
        s2_field: result.s2_field,
        s3_field: result.s3_field,
        oneDigitF: result.oneDigitF,
        oneDigitS: result.oneDigitS,
        twoDigitF: result.twoDigitF,
        twoDigitS: result.twoDigitS,
        threeDigitF: result.threeDigitF,
        threeDigitS: result.threeDigitS,
        fourDigitF: result.fourDigitF,
        fourDigitS: result.fourDigitS,
      });
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      getDrawId(location.state);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    saveSetting();
  };
  return (
    <div className="user-container">
      <div className="heading-div">
        <h3 className="featureTitle">
          <b>{!location.state ? "Add New Draw" : "Edit Draw"}</b>
        </h3>
      </div>
      <form className="form-main" onSubmit={handleSubmit}>
        <div className="form-left">
          {" "}
          <div className="form-group">
            <label className="my-label" htmlFor="userid">
              User Id :
            </label>
            <Input
              type="text"
              className="form-control"
              id="user_id"
              name="user_id"
              value={formValues.user_id}
              onChange={handleChange}
              placeholder="User Id"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="userid">
              Draw Name :
            </label>
            <Input
              type="text"
              className="form-control"
              id="draw_name"
              name="draw_name"
              value={formValues.draw_name}
              onChange={handleChange}
              placeholder="Draw Name"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="start_date">
              Start Date :
            </label>
            <Input
              type="date"
              className="form-control"
              id="start_date"
              name="start_date"
              value={formValues.start_date}
              onChange={handleChange}
              placeholder="Start Date"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="start_time">
              Start Time :
            </label>
            <Input
              type="time"
              className="form-control"
              id="start_time"
              name="start_time"
              value={formValues.start_time}
              onChange={handleChange}
              placeholder="Start Time"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="end_date">
              End Date :
            </label>
            <Input
              type="date"
              className="form-control"
              id="end_date"
              name="end_date"
              value={formValues.end_date}
              onChange={handleChange}
              placeholder="End Date"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="end_time">
              End Time :
            </label>
            <Input
              type="time"
              className="form-control"
              id="end_time"
              name="end_time"
              value={formValues.end_time}
              onChange={handleChange}
              placeholder="End Time"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="f_field">
              F :
            </label>
            <Input
              type="text"
              className="form-control"
              id="f_field"
              name="f_field"
              value={formValues.f_field}
              onChange={handleChange}
              placeholder="f_field"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="s1_field">
              S1 :
            </label>
            <Input
              type="text"
              className="form-control"
              id="s1_field"
              name="s1_field"
              value={formValues.s1_field}
              onChange={handleChange}
              placeholder="s1_field"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="s2_field">
              S2 :
            </label>
            <Input
              type="text"
              className="form-control"
              id="s2_field"
              name="s2_field"
              value={formValues.s2_field}
              onChange={handleChange}
              placeholder="s2_field"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="s3_field">
              S3 :
            </label>
            <Input
              type="text"
              className="form-control"
              id="s3_field"
              name="s3_field"
              value={formValues.s3_field}
              onChange={handleChange}
              placeholder="s3_field"
              required
            />
          </div>
        </div>
        <div className="form-right">
          <div className="number-form">
            <div className="digit-title-div">
              <span className="digit-title"> One Digit Number :</span>
              <span className="digit-title"> Two Digit Number :</span>
              <span className="digit-title"> Three Digit Number :</span>
              <span className="digit-title"> Four Digit Number :</span>
            </div>
            <div className="limit-group">
              <div className="header">First (F)</div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="oneDigitF"
                    value={formValues.oneDigitF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="twoDigitF"
                    value={formValues.twoDigitF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="threeDigitF"
                    value={formValues.threeDigitF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="fourDigitF"
                    value={formValues.fourDigitF}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
            </div>
            <div className="limit-group">
              <div className="header">Second (S)</div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="oneDigitS"
                    value={formValues.oneDigitS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="twoDigitS"
                    value={formValues.twoDigitS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="threeDigitS"
                    value={formValues.threeDigitS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
              <div className="min-max">
                <div className="min">
                  <Input
                    type="number"
                    name="fourDigitS"
                    value={formValues.fourDigitS}
                    onChange={handleChange}
                    required
                    min={"0"}
                    max={"100000"}
                  />
                </div>
              </div>
            </div>
          </div>
          {!location.state ? (
          <div className="form-btn-div" style={{ marginRight: "10px" }}>
            <button className="save-btn" type="submit">
              Save
            </button>
          </div>
        ) : null}
        </div>
      </form>
      <div style={{ display: "flex", width: "20%", marginLeft: "50%" }}>
        {/* <div className="form-btn-div" style={{ marginRight: "10px" }}>
          <button className="save-btn" onClick={getDrawId}>
            Search
          </button>
        </div> */}
        {location.state ? (
          <>
            <div className="form-btn-div" style={{ marginRight: "10px" }}>
              <button className="save-btn" onClick={updateSetting}>
                Update
              </button>
            </div>
            {/* <div className="form-btn-div">
              <button className="save-btn" onClick={deleteSetting}>Delete</button>
            </div> */}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AddDraw;
