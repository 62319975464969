import axios from 'axios';
import { BASE_URL } from '../Config/Constants';

// POST request with body
export const adminLogin = async (data) => {
  try {
      const response = await axios.post(`${BASE_URL}/auth/signin`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const uploadImageNotify = async (data) => {
  try {
    let formData = new FormData()
    formData.append('image',data)
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const response = await axios.post(`${BASE_URL}/notify/upload`, formData,  config);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


// GET request with optional query parameters
// export const fetchData = async (queryParams = {}) => {
//   try {
//     const response = await BASE_URL.get('/endpoint', { params: queryParams });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// Add more API functions as needed
