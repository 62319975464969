import React, { useEffect, useState } from "react";
import "./Book.css";
import { Input, Modal } from "antd";
import DropDown from "../../Components/Dropdown/DropDown";
import DynamicTable from "../../Components/Table/DynamicTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Image_URL, translations } from "../../Config/Constants";
// import notificationImage from "../../Assets/Images/Action/Notification.jpeg";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../Components/Buttons/Button";
import TextArea from "antd/es/input/TextArea";
import "react-toastify/dist/ReactToastify.css";
import {
  generateCombinations,
  getDraw,
  saveCombinations,
} from "../../services/DrawServices";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getCombinationsById, getUserById } from "../../services/UserServices";
import { uploadImageNotify } from "../../services/AdminServices";

const Book = () => {
  const [language, setLanguage] = useState("en");
  const [dealerId, setDealerId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({
    number: "",
    fValue: "",
    sValue: "",
    digitLength: "",
  });
  const [smsInput, setSmsInput] = useState("");
  const [drawDetails, setDrawDetails] = useState([]);
  const [drawId, setDrawId] = useState("");
  const [draw_id, setDraw_ID] = useState("");
  const [balance, setBalance] = useState("");
  const [userName, setUserName] = useState("");
  const [drawName, setDrawName] = useState("");
  const [commission, setCommission] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [formValues, setFormValues] = useState({
    oneDigitMinF: "",
    oneDigitMaxF: "",
    twoDigitMinF: "",
    twoDigitMaxF: "",
    threeDigitMinF: "",
    threeDigitMaxF: "",
    fourDigitMinF: "",
    fourDigitMaxF: "",
    oneDigitMinS: "",
    oneDigitMaxS: "",
    twoDigitMinS: "",
    twoDigitMaxS: "",
    threeDigitMinS: "",
    threeDigitMaxS: "",
    fourDigitMinS: "",
    fourDigitMaxS: "",
  });

  useEffect(() => {
    const getData = async () => {
      const response = await getDraw();
      console.log("response===>", response);
      setDrawDetails(response);
    };
    getData();
  }, []);

  const handleGetUserById = async () => {
    setUserName("");
    setBalance("");
    setData([]);
    setInputValues({
      number: "",
      fValue: "",
      sValue: "",
      digitLength: "",
    });
    const response = await getUserById(dealerId);
    setCommission(response.commission)
    setFormValues({
      oneDigitMinF: response.oneDigitMinF,
      oneDigitMaxF: response.oneDigitMaxF,
      twoDigitMinF: response.twoDigitMinF,
      twoDigitMaxF: response.twoDigitMaxF,
      threeDigitMinF: response.threeDigitMinF,
      threeDigitMaxF: response.threeDigitMaxF,
      fourDigitMinF: response.fourDigitMinF,
      fourDigitMaxF: response.fourDigitMaxF,
      oneDigitMinS: response.oneDigitMinS,
      oneDigitMaxS: response.oneDigitMaxS,
      twoDigitMinS: response.twoDigitMinS,
      twoDigitMaxS: response.twoDigitMaxS,
      threeDigitMinS: response.threeDigitMinS,
      threeDigitMaxS: response.threeDigitMaxS,
      fourDigitMinS: response.fourDigitMinS,
      fourDigitMaxS: response.fourDigitMaxS,
    });
    setUserName(response?.user_name ? response.user_name : "");
    setBalance(response.balance ? response.balance : 0);
    const cresponse = await getCombinationsById(dealerId, draw_id);
    let formatArray = [];
    for (let i = 0; i < cresponse.length; i++) {
      let formatObject = {
        key: data.length + (i + 1), // Assign a unique key if needed
        num: cresponse[i].num,
        f: cresponse[i].f,
        s: cresponse[i].s,
      };
      formatArray.push(formatObject); // Push the formatted object into the array
    }
    setData(formatArray); // If you want to add this to existing data
  };

  // console.log("draw details inside state is", drawDetails);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue =
      name === "number" ||
      name === "fValue" ||
      name === "sValue" ||
      name === "digitLength"
        ? Number(value) // Convert to number for specific fields
        : value; // Keep as string for others

    setInputValues({
      ...inputValues,
      [name]: newValue,
    });
  };

  const handleSmsInputChange = (e) => {
    const value = e.target.value;
    setSmsInput(value);
  };
  // console.log("SMS Input value is", smsInput);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const regex = /^\d{3},\d{2},\d{2}$/; // Regex for format 123,22,33
    let smsString = smsInput.split("\n");
    let formattedArray = [];
    for (let i = 0; i < smsString.length; i++) {
      if (regex.test(smsString[i])) {
        const parts = smsString[i].split(",");
        const randomKey = Math.floor(Math.random() * 1000) + 1;
        const formattedObject = {
          key: randomKey, // Assign a unique key if needed
          num: parseInt(parts[0], 10),
          f: parseInt(parts[1], 10),
          s: parseInt(parts[2], 10),
        };
        formattedArray.push(formattedObject); // Push the formatted object into the array
      } else {
        toast.warn("Invalid format. Please enter in '123,22,33' format");
      }
    }
    setData(...data, formattedArray); // If you want to add this to existing data
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSchemeChange = async (cLength, cDigit) => {
    const numberAsString = String(inputValues.number);
    if (!numberAsString || numberAsString.length < cLength) {
      toast.warn(`Input number must have length of ${cLength} digit`);
    } else if (inputValues.fValue.length === 0) {
      toast.warn(`First  must be non-zero`);
    } else if (inputValues.sValue.length === 0) {
      toast.warn(`Second must be non-zero`);
    } else {
      console.log("input value===>", inputValues);
      inputValues.digitLength = cDigit;
      const result = await generateCombinations(inputValues);
      if (result) {
        // console.log("the data got from combination api is", data);
        // console.log("the result got from combination api is", result);
        setData(data.concat(result));
      }
    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const searchCombination = () => {
    const filteredData = data.filter(
      (item) =>
        item.num.toString().includes(searchTerm) ||
        item.f.toString().includes(searchTerm) ||
        item.s.toString().includes(searchTerm)
    );

    // Update the state with the filtered data
    setData(filteredData);

    // If you want to reset the search term after search, uncomment the following line
    // setSearchTerm('');
  };

  async function determineFirstDigitCount(number) {
    // Convert the number to a string to find its length
    const numberString = Math.abs(number).toString();

    // Determine the digit count and print the result
    if (numberString.length === 1) {
      if (
        !(
          number >= formValues.oneDigitMinF && number <= formValues.oneDigitMaxF
        )
      ) {
        alert("First number not machtes the limit");
        return 0;
      }
    } else if (numberString.length === 2) {
      if (
        !(
          number >= formValues.twoDigitMinF && number <= formValues.twoDigitMaxF
        )
      ) {
        alert("First number not machtes the limit");
        return 0;
      }
    } else if (numberString.length === 3) {
      if (
        !(
          number >= formValues.threeDigitMinF &&
          number <= formValues.threeDigitMaxF
        )
      ) {
        alert("First number not machtes the limit");
        return 0;
      }
    } else if (numberString.length === 4) {
      if (
        !(
          number >= formValues.fourDigitMinF &&
          number <= formValues.fourDigitMaxF
        )
      ) {
        alert("First number not machtes the limit");
        return 0;
      }
    } else {
      alert("Number has more than 4 digits");
      return 0;
    }
    return 1;
  }

  async function determineSecondDigitCount(number) {
    // Convert the number to a string to find its length
    const numberString = Math.abs(number).toString();

    // Determine the digit count and print the result
    if (numberString.length === 1) {
      if (
        !(
          number >= formValues.oneDigitMinS && number <= formValues.oneDigitMaxS
        )
      ) {
        alert("Second number not machtes the limit");
        return 0;
      }
    } else if (numberString.length === 2) {
      if (
        !(
          number >= formValues.twoDigitMinS && number <= formValues.twoDigitMaxS
        )
      ) {
        alert("Second number not machtes the limit");
        return 0;
      }
    } else if (numberString.length === 3) {
      if (
        !(
          number >= formValues.threeDigitMinS &&
          number <= formValues.threeDigitMaxS
        )
      ) {
        alert("Second number not machtes the limit");
        return 0;
      }
    } else if (numberString.length === 4) {
      if (
        !(
          number >= formValues.fourDigitMinS &&
          number <= formValues.fourDigitMaxS
        )
      ) {
        alert("Second number not machtes the limit");
        return 0;
      }
    } else {
      alert("Number has more than 4 digits");
      return 0;
    }
    return 1;
  }

  // const handleAddRow = async () => {
  //   let firstCheck = await determineFirstDigitCount(inputValues.fValue);
  //   let secondCheck = await determineSecondDigitCount(inputValues.sValue);
  //   if (firstCheck == 1 || secondCheck == 1) {
  //     const formattedObject = {
  //       key: data.length + 1, // Assign a unique key if needed
  //       num: inputValues.number,
  //       f: inputValues.fValue,
  //       s: inputValues.sValue,
  //     };

  //     let formattedArray = [];
  //     formattedArray.push(formattedObject); // Push the formatted object into the array
  //     setData([...data, formattedObject]); // If you want to add this to existing data

  //     setIsModalOpen(false);
  //   }
  // };

  const handleAddRow = async () => {
    try {
      const firstCheck = await determineFirstDigitCount(inputValues.fValue);
      const secondCheck = await determineSecondDigitCount(inputValues.sValue);
  
      if (firstCheck === 1 || secondCheck === 1) {
        const newObject = {
          key: data.length + 1, // Assign a unique key if needed
          num: inputValues.number,
          f: inputValues.fValue,
          s: inputValues.sValue,
        };
  
        // Check for duplicates
        const isDuplicate = data.some(row => 
          row.num === newObject.num &&
          row.f === newObject.f &&
          row.s === newObject.s
        );
  
        if (!isDuplicate) {
          setData(prevData => [...prevData, newObject]);
          setIsModalOpen(false);
        } else {
          console.log("Duplicate row not added.");
        }
      }
    } catch (error) {
      console.error("Error adding row:", error);
    }
  };  

  

  const saveCombination = async () => {
    console.log("Save Comination Handler trigger");
    const result = await saveCombinations(dealerId, drawId, data);
    if (result) {
      toast.success(result.message);
    }
  };
  const handleChangeLanguage = (lang) => {
    setLanguage(lang);
  };

  const t = translations[language]; // shorthand for translation

  const handleDelete = (e) => {
    const newData = data.filter((item) => item.key !== e);
    setData(newData);
    toast.success("Entry Deleted Successfully");
  };
  const handleEdit = (e) => {
    console.log("e==>", e);
    const itemToEdit = data.find((item) => item.key === e);
    // Set this item as the current item to be edited
    setCurrentEdit(itemToEdit);
    // Open the modal
    setIsEditModalOpen(true);
  };
  const handleChange = (value) => {
    // console.log("the value inside Handle Change is", value.value);
    // console.log(value);
    setDraw_ID(value.value);
    setDrawName(value.label)
    setDrawId(value.key);
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedTime = currentDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  const selectOptions = [
    {
      value: "ledger",
      label: "LEDGER",
    },
    {
      value: "dailyBill",
      label: "Daily Bill",
    },
  ];

  const ritmuOptions = [
    {
      value: "ritmu1",
      label: "Ritmu1",
    },
    {
      value: "ritmu2",
      label: "Ritmu2",
    },
  ];

  const columns = [
    {
      title: "Num",
      dataIndex: "num",
    },
    {
      title: "F",
      dataIndex: "f",
    },
    {
      title: "S",
      dataIndex: "s",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <span className="action-span" onClick={() => handleEdit(record.key)}>
            <EditOutlined className="action-btn" />
          </span>
          <span
            className="action-span"
            onClick={() => handleDelete(record.key)}
          >
            <DeleteOutlined className="action-btn" />
          </span>
        </div>
      ),
    },
  ];
  const calculateRemainingTime = (endTime) => {
    const now = new Date();
    const end = new Date(endTime);
    const timeDiff = end - now;

    if (timeDiff <= 0) {
      // Draw has ended or is ending now
      return "Draw ended";
    }

    let seconds = Math.floor(timeDiff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    hours %= 24;
    minutes %= 60;
    seconds %= 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const userRole = localStorage.getItem("Role");

  // Function to generate PDF
  // const generatePDF = () => {
  //   // Create a new instance of jsPDF
  //   const doc = new jsPDF();

  //   // Set font size and type
  //   doc.setFontSize(12);
  //   doc.setFont("arial");

  //   // Add content to the PDF
  //   doc.text(`Dealer Id : ${dealerId}\t\tDealer Name : ${userName}`, 10, 10);
  //   doc.text(`Draw : ${drawName.trim().split(' ')[0]}\t\tBooking Date : ${formattedDate}`, 10, 20);

  //   // Define table headers and rows
  //   const headers = ["Numbers", "F", "S"];
  //   let dataList = [];
  //   let fcount = 0
  //   let scount = 0

  //   for (let i=0; i<data.length; i++){
  //     // console.log(data)
  //     dataList.push([data[i].num,data[i].f,data[i].s])
  //     fcount = parseInt(fcount) + data[i].f
  //     scount = parseInt(scount) + data[i].s
  //   }

  //   dataList.push(["Total", fcount, scount])

  //   // Create the table
  //   doc.autoTable({
  //     startY: 30,
  //     head: [headers],
  //     body: dataList,
  //     theme: "grid",
  //     columnStyles: {
  //       0: { cellWidth: 40 },
  //       1: { cellWidth: 20 },
  //       2: { cellWidth: 20 },
  //     },
  //   });
  //   let totalSum = parseInt(fcount) + parseInt(scount)
  //   let totalComission = parseInt(totalSum) * (parseInt(commission)/100)
  //   // Add remaining content
  //   doc.text("Total: "+ totalSum , 10, doc.autoTable.previous.finalY + 10);
  //   doc.text(
  //     `Commission % : ${commission}\t\tCommission Amount:  ${totalSum} x ${commission}% = ${parseInt(totalComission).toFixed(2)}`,
  //     10,
  //     doc.autoTable.previous.finalY + 20
  //   );
  //   doc.text(
  //     `Grand Total: ${totalSum} - ${parseInt(totalComission).toFixed(2)} = ${totalSum - totalComission}`,
  //     10,
  //     doc.autoTable.previous.finalY + 30
  //   );

  //   // Save the PDF
  //   doc.save("output.pdf");
  // };

  const generatePDF = () => {
    // Check if jsPDF and autoTable plugins are loaded
    if (typeof jsPDF === "undefined") {
      console.error("jsPDF is not loaded.");
      return;
    }
    if (typeof jsPDF.API.autoTable === "undefined") {
      console.error("jsPDF autoTable plugin is not loaded.");
      return;
    }
  
    // Create a new instance of jsPDF
    const doc = new jsPDF();
  
    // Set font size and type
    doc.setFontSize(12);
    doc.setFont("arial");
  
    // Add header content to the PDF
    doc.text(`G T L`, 10, 10);
    doc.text(`MULTANI INTER`, 10, 20);
    doc.text(`Date: ${formattedDate}`, 60, 10);
    doc.text(`Draw # ${drawName.trim().split(' ')[0]}`, 60, 20);
    doc.text(`P # 36`, 110, 10);
    doc.text(`0300 202 9950, 0309 252 9950`, 110, 20);
  
    // Define table headers and rows
    const headers = [
      ["S#", "Number", "1st", "2nd", "Number", "1st", "2nd"]
    ];
  
    let dataList = [];
    let fcount = 0;
    let scount = 0;
  
    for (let i = 0; i < data.length; i += 2) {
      let row = [
        i + 1,
        data[i].num,
        data[i].f,
        data[i].s,
        (i + 1 < data.length) ? data[i + 1].num : "",
        (i + 1 < data.length) ? data[i + 1].f : "",
        (i + 1 < data.length) ? data[i + 1].s : ""
      ];
  
      dataList.push(row);
  
      fcount += data[i].f;
      scount += data[i].s;
      if (i + 1 < data.length) {
        fcount += data[i + 1].f;
        scount += data[i + 1].s;
      }
    }
  
    dataList.push(["Total", "", fcount, scount, "", "", ""]);
  
    let totalSum = fcount + scount;
    let totalCommission = totalSum * (parseInt(commission) / 100);
  
    // Create the table
    doc.autoTable({
      startY: 30,
      head: headers,
      body: dataList,
      theme: "grid",
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 25 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
        6: { cellWidth: 25 }
      },
      styles: { cellPadding: 2, fontSize: 8 }
    });
  
    // Add remaining content
    doc.text(`Commission % : ${commission}\t\tCommission Amount: ${totalSum.toFixed(2)} x ${commission}% = ${totalCommission.toFixed(2)}`, 10, doc.autoTable.previous.finalY + 10);
    doc.text(`Grand Total: ${totalSum.toFixed(2)} - ${totalCommission.toFixed(2)} = ${(totalSum - totalCommission).toFixed(2)}`, 10, doc.autoTable.previous.finalY + 20);
  
    // Save the PDF
    doc.save("output.pdf");
  };  
  

  const [file, setFile] = useState(`${Image_URL}/notification.png`);
  const [image, setImage] = useState("");
  function handleImageChange(e) {
      // console.log(e.target.files[0]);
      setImage(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
  }

  const saveNotification = async () => {
    const result = await uploadImageNotify(image);
    if (result) {
      toast.info(result.message);
    }
  };

  return (
    <div className="book-container">
      <Modal
        title="Edit Book Entry"
        width={"350px"}
        visible={isEditModalOpen}
        onOk={() => {
          const updatedData = data.map((item) => {
            if (item.key === currentEdit.key) {
              return currentEdit; // return the updated item
            }
            return item; // return the item as is
          });
          setData(updatedData);
          setIsEditModalOpen(false);
        }}
        onCancel={() => setIsEditModalOpen(false)}
        okText={"Confirm"}
      >
        <div className="modal-main">
          <div className="no-input-div">
            <span className="no-span">Number</span>
            <Input
              className="no-input-modal"
              type="Number"
              placeholder="Number"
              value={currentEdit?.num}
              onChange={(e) => {
                setCurrentEdit({ ...currentEdit, num: e.target.value });
              }}
            />
          </div>
          <div className="no-input-div">
            <span className="no-span">First</span>
            <Input
              className="no-input-modal"
              placeholder="First"
              value={currentEdit?.f}
              readOnly
            />
          </div>{" "}
          <div className="no-input-div">
            <span className="no-span">Second</span>
            <Input
              className="no-input-modal"
              placeholder="Second"
              value={currentEdit?.s}
              readOnly
            />
          </div>
        </div>
      </Modal>
      <ToastContainer />
      <div className="book-left">
        <div className="book-left-upper-card">
          <div className="user-info-div">
            <div className="user-name-div">
              <span className="name">Draw Name:</span>
              <DropDown
                options={drawDetails.map((e) => ({
                  label: ` ${e.draw_name} ${calculateRemainingTime(
                    e.end_date
                  )}`,
                  value: e.id,
                }))}
                onChange={handleChange}
                style={{ width: "100%", height: 35, fontFamily: "sans-serif" }}
              />
            </div>
            <div className="user-name-div">
              <span className="name">Dealer ID:</span>
              <Input
                placeholder="User Id"
                type="search"
                value={dealerId}
                onChange={(e) => setDealerId(e.target.value)}
                className="input"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleGetUserById();
                  }
                }}
              />
            </div>
            {userRole == "admin" ? (
              <div className="user-name-div">
                <span className="name">Dealer Name:</span>
                <Input
                  placeholder="User Name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className="input"
                />
              </div>
            ) : null}
            <div className="user-name-div">
              <span className="name">Balance:</span>
              <Input
                placeholder="12354"
                value={balance}
                className="input"
                readOnly
              />
            </div>
          </div>
          <div className="draw-info-div">
            <div className="draw-name-div">
              <span className="name">Current Date:</span>
              <span className="book-center-date-span">{formattedDate}</span>
            </div>
            <div className="draw-name-div">
              <span className="name">Current Time:</span>
              <span className="book-center-date-span">
                Time: <span>{formattedTime}</span>
              </span>{" "}
            </div>
            <div className="print-btn-div">
              <button className="print-btn" onClick={generatePDF}>Print</button>
            </div>
          </div>
        </div>
        <div className="book-left-lower-card">
          <div className="table-div">
            <DynamicTable
              data={data}
              columns={columns}
              pageSize={4}
              setData={setData}
            />
          </div>
          <div className="no-input-div">
            <div className="num-div">
              <span className="no-span">No:</span>
              <Input
                name="number"
                type="number"
                value={inputValues.number}
                onChange={handleInputChange}
                required
                className="no-input"
              />
            </div>{" "}
            <div className="num-div">
              <span className="no-span">F:</span>
              <Input
                type="number"
                name="fValue"
                value={inputValues.fValue}
                onChange={handleInputChange}
                className="no-input"
                required
                min={"0"}
              />
            </div>
            <div className="num-div">
              <span className="no-span">S:</span>
              <Input
                type="number"
                name="sValue"
                required
                value={inputValues.sValue}
                onChange={handleInputChange}
                className="no-input"
                min={"0"}
              />
            </div>
            <button className="search-btn" onClick={handleAddRow}>
              Add
            </button>
          </div>
          <div className="search-div">
            <button className="search-btn" onClick={saveCombination}>
              Save
            </button>
            <button className="search-btn" onClick={showModal}>
              Paste SMS
            </button>
          </div>
          <Modal
            title="Paste SMS"
            open={isModalOpen}
            onOk={handleOk}
            okText={"Confirm"}
            onCancel={handleCancel}
          >
            <div className="modal-main">
              <TextArea
                rows={2}
                placeholder="Paste SMS here (format: 123,22,33)"
                value={smsInput}
                onChange={handleSmsInputChange}
                required
              />
            </div>
          </Modal>
        </div>
      </div>
      <div className="book-right">
        <div className="book-right-container">
          <div className="notification-div">
            <span className="file-scheme">Notifications</span>
            <img
              src={file}
              alt="notification.png"
              className="notification-image"
            />
          </div>
          {userRole == "admin" ? (
            <>
              <input type="file" title={'upload image'} onChange={handleImageChange}  />
              <button className="search-btn" style={{width:"80%"}} onClick={saveNotification}>
                Upload To DB
              </button>
            </>              
            ):null
            }
          <span className="file-scheme">Schemes</span>
          <div className="lang-div">
            <span className="lang-span">Language</span>
            <div className="lang-radio-div">
              <label className="lang-radio-label">
                <input
                  type="radio"
                  style={{ cursor: "pointer" }}
                  value="en"
                  name="language"
                  checked={language === "en"}
                  onChange={() => handleChangeLanguage("en")}
                />
                Eng
              </label>
              <label className="lang-radio-label">
                <input
                  type="radio"
                  style={{ cursor: "pointer" }}
                  value="hi"
                  name="language"
                  checked={language === "hi"}
                  onChange={() => handleChangeLanguage("hi")}
                />
                Hindi
              </label>
              <label className="lang-radio-label">
                <input
                  type="radio"
                  style={{ cursor: "pointer" }}
                  value="ur"
                  name="language"
                  checked={language === "ur"}
                  onChange={() => handleChangeLanguage("ur")}
                />
                Urdu
              </label>
            </div>
          </div>
          <div className="buttons-div">
            <Button
              label={t.button1}
              className="book-button"
              onClick={() => handleSchemeChange(3, 3)}
            />
            <Button
              label={t.button2}
              className="book-button"
              onClick={() => handleSchemeChange(3, 2)}
            />
            <Button
              label={t.button3}
              className="book-button"
              onClick={() => handleSchemeChange(4, 3)}
            />
            <Button
              label={t.button4}
              className="book-button"
              onClick={() => handleSchemeChange(4, 2)}
            />
            <Button
              label={t.button5}
              className="book-button"
              onClick={() => handleSchemeChange(5, 3)}
            />
            <Button
              label={t.button6}
              className="book-button"
              onClick={() => handleSchemeChange(5, 2)}
            />
            <Button
              label={t.button7}
              className="book-button"
              onClick={() => handleSchemeChange(6, 3)}
            />
            <Button
              label={t.button8}
              className="book-button"
              onClick={() => handleSchemeChange(6, 2)}
            />
            <Button
              label={t.button10}
              className="book-button"
              onClick={() => handleSchemeChange(4, 4)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
