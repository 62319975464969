import React from "react";
import "./Navbar.css";
import { BarsOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const Navbar = ({ onOpenDrawer }) => {
  const navigate = useNavigate();
  const myLogout = () => {
    console.log("Logout Handler Triger");
    localStorage.removeItem("Token");
    localStorage.removeItem("Role");
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="topBar-bc">
      <div className="topbar-left">
        <BarsOutlined className="bars" onClick={onOpenDrawer} />
        <h3 className="dashboardTop-bc">Dashboard</h3>
      </div>
      <div className="topbar-right">
        <h3 className="topbar-user" onClick={myLogout}>
          Logout
        </h3>
        <LogoutOutlined
          style={{ fontSize: "20px", color: "#6f2727", marginInline: 20 }}
          onClick={myLogout}
        />
      </div>
    </div>
  );
};

export default Navbar;
