import React, { useState } from "react";
import { Table, Button } from "antd";

const DynamicTable = ({ columns, data, pagination, pageSize, setData }) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleDelete = () => {
    const newData = data.filter((item) => !selectedRowKeys.includes(item.key));
    setSelectedRowKeys([]);
    // Call a function here to update your data source with newData
    // console.log("Deleted:", selectedRowKeys);
    setData(newData)
  };



  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const paginationConfig = pagination ? { pageSize: pageSize || 5 } : false;

  return (
    <div>
          <Button onClick={handleDelete} disabled={selectedRowKeys.length === 0}>
        Delete Selected
      </Button><span> Selected Rows: {selectedRowKeys.length}</span>
      <Table
        columns={columns}
        dataSource={data}
        pagination={paginationConfig}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default DynamicTable;
