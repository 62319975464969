import { Select } from "antd";
import React from "react";

const DropDown = ({ value, options, onChange, style }) => {
  return (
    <Select
      labelInValue
      value={value}
      style={style}
      onChange={onChange}
      options={options}
    />
  );
};

export default DropDown;
