import React, { useEffect, useState } from "react";
import DynamicTable from "../../Components/Table/DynamicTable";
import Button from "../../Components/Buttons/Button";
import "./Voucher.css";
import { getDrawInfo } from "../../services/DrawServices";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Voucher = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const response = await getDrawInfo();
      console.log("The response is >>>", response);
      setData(response);
      const transformedData = response.map((item, index) => ({
        key: item.id, // Unique key for each row
        num: index + 1, // Just a sequential number for the row
        name: item.user.user_name,
        draw: item.draw.draw_name,
        cTime: item.draw.createdAt,
        combinations: [{num:item.num,f:item.f,s:item.s}], // Add this line
      }));
      setTableData(transformedData);
    };
    getData();
  }, []);
  const downloadPdf = (combinations) => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Format data for the table
    const tableData = combinations.map((item) => [
      item.num.toString(),
      item.f.toString(),
      item.s.toString(),
    ]);

    // Define columns for the table
    const tableColumns = ["Num", "F", "S"];

    // Add table to document
    doc.autoTable(tableColumns, tableData, {
      startY: 20,
    });

    // Save the PDF
    doc.save("combinations.pdf");
  };
  const columns = [
    {
      title: "#",
      dataIndex: "num",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Draw",
      dataIndex: "draw",
    },
    {
      title: "Create Time",
      dataIndex: "cTime",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 10,
          }}
        >
          <Button
            label={"Download"}
            className="book-button"
            onClick={() => downloadPdf(record.combinations)}
          />
        </div>
      ),
    },
  ];
  // const data = Array.from({ length: 40 }, (_, index) => {
  //   const num = index + 1;
  //   return {
  //     key: num.toString(),
  //     num,
  //     date: `2023-11-${num < 10 ? "0" : ""}${num}`,
  //     draw: `Ritmu ${num} Pm`,
  //     cTime: `2023-11-${num < 10 ? "0" : ""}${num}`,
  //   };
  // });
  return (
    <div>
      <div className="voucher-table-card">
        <DynamicTable
          data={tableData}
          columns={columns}
          pagination={tableData.length > 6 ? true : false}
          pageSize={6}
          onRow={(record) => ({
            onClick: () => {
              console.log("this is download record", record); // Check what's inside record
              downloadPdf(record.combinations); // Assuming combinations data is part of the record
            },
          })}
        />
      </div>
    </div>
  );
};

export default Voucher;
