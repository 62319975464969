import axios from 'axios';
import { BASE_URL } from '../Config/Constants';

// POST request with body
export const userRegister = async (data) => {
  try {
      const response = await axios.post(`${BASE_URL}/auth/signup`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const updateRegister = async (data) => {
  try {
      const response = await axios.post(`${BASE_URL}/auth/update`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const userChangePassword = async (data,userId) => {
  console.log("the value receives inside services functipon", data, "and",userId);
  try {
      const response = await axios.patch(`${BASE_URL}/auth/change-password/${userId}`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/getbyid/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const deleteUserById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/deletebyid/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCombinationsById = async (id,did) => {
  try {
    const response = await axios.get(`${BASE_URL}/combination/getbyid/${id}/${did}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/getall`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getIdUserNew = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/getnewid`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};