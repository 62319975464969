export const BASE_URL="https://admin.multanieprize.com/api"
export const Image_URL="https://admin.multanieprize.com/uploads"

// export const Image_URL="http://localhost:8000/uploads"
// export const BASE_URL="http://localhost:8000/api"
// export const BASE_URL="http://192.168.100.37:8000/api"

export const translations = {
  en: {
    fourth: "Fourth",
    front: "Front",
    mid: "Mid",
    back: "Back",
    fileScheme:"File Scheme",
    button1: "3 Digit Combinations",
    button2: "2 Digit Combinations",
    button3: "4 Figure 3 Digit Combinations",
    button4: "4 Figure 2 Digit combinations",
    button5: "5 Figure 3 Digit Combinations",
    button6: "5 Figure 2 Digit Combinations",
    button7: "6 Figure 3 Digit Combinations",
    button8: "6 Figure 2 Digit Combinations",
    button9: "3 Digit + 2 Digit",
    button10: "4 Digit Combinations",
    button11: "2 Digit 3 Placements",
  },
  hi: {
    fourth: "चौथी",
    front: "सामने",
    mid: "मध्य",
    back: "पीछे",
    fileScheme:"फ़ाइल योजना",
    button1: "3 अंकों का संयोजन",
    button2: "2 अंकों का संयोजन",
    button3: "4 आकृति 3 अंक संयोजन",
    button4: "4 आकृति 2 अंक संयोजन",
    button5: "5 चित्र 3 अंकीय संयोजन",
    button6: "5 चित्र 2 अंक संयोजन",
    button7: "6 चित्र 3 अंकीय संयोजन",
    button8: "6 चित्र 2 अंकीय संयोजन",
    button9: "3 अंक + 2 अंक",
    button10: "4 अंकीय संयोजन",
    button11: "2 अंक 3 प्लेसमेंट",
  },
  ur: {
    fourth: "فورتھ",
    front: "فرنٹ",
    mid: "مڈل",
    back: "بیک",
    fileScheme:"فائل سکیم",
    button1: "پلٹی ٹنڈولہ",
    button2: "پلٹی آکڑہ",
    button3: "4 فگر ٹنڈولہ ",
    button4: "4 فگر آکڑہ",
    button5: "5 فگر ٹنڈولہ",
    button6: "5 فگر آکڑہ",
    button7: "6 فگر ٹنڈولہ",
    button8: "6 فگر آکڑہ",
    button9: "ٹنڈولہ + آکڑہ",
    button10: "پلٹی پنگھوڑہ",
    button11: "فرنٹ - مڈل - بیک - آکڑہ",
  },
};


