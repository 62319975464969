import React, { useEffect, useState } from 'react'
import './VoucherList.css'
import { useNavigate } from 'react-router-dom';
import { deleteVoucherById, getAllVouchers } from '../../services/VoucherServices';

const VoucherList = () => {
    const navigate = useNavigate();
    const [voucherList, setVoucherList] = useState([])
    const [updateList, setUpdateList] = useState(false)
    useEffect(()=>{
      getAllVouchers().then((data)=>{
        setVoucherList(data)
      })
    },[updateList])

    const handledeleteUserById = async (id,uid)=>{
      console.log(id)
      const response = await deleteVoucherById(id,uid);
      console.log(response)
      alert(response.message)
      setUpdateList(!updateList)
    }

  return (
    <>
      <div className='listcontainer'>
      <div style={{display:'flex',justifyContent:'center'}}>
        <h2>List of Vouchers</h2>
        <a
          href='/portal/addVoucher'
          className='btn-add'
        >
          Add
        </a>
      </div>
        <div className='user'>
          <h4>User ID</h4>
          <h4>Description</h4>
          <h4>Edit</h4>
          <h4>Delete</h4>
        </div>

        <hr />

        {voucherList.length > 0 && 
          voucherList.map((voucher, idx) => (
            <div key={idx} className='user'>
              <h4>{voucher.user_id}</h4>
              <h4>{voucher.description}</h4>
              <button
                onClick={() => navigate('/portal/addVoucher', { state: voucher.id })}
                className='btn-edit'
              >
                Edit
              </button>
              <button
                onClick={() => handledeleteUserById(voucher.id,voucher.user_id)}
                className='btn'
              >
                Delete
              </button>
            </div>
          ))}
      </div>
    </>
  )
}

export default VoucherList