import React, { useEffect, useState } from 'react'
import './UsersList.css'
import { deleteUserById, getAllUsers } from '../../services/UserServices'
import { useNavigate } from 'react-router-dom';

const UsersList = () => {
    const navigate = useNavigate();
    const [userList, setUserList] = useState([])
    const [updateList, setUpdateList] = useState(false)
    useEffect(()=>{
      getAllUsers().then((data)=>{
        setUserList(data)
      })
    },[updateList])

    const handledeleteUserById = async (id)=>{
      console.log(id)
      const response = await deleteUserById(id);
      console.log(response)
      alert(response.message)
      setUpdateList(!updateList)
    }

  return (
    <>
      <div className='listcontainer'>
      <div style={{display:'flex',justifyContent:'center'}}>
        <h2>List of Users</h2>
        <a
          href='/portal/user'
          className='btn-add'
        >
          Add
        </a>
      </div>
        <div className='user'>
          <h4>User ID</h4>
          <h4>Username</h4>
          <h4>Edit</h4>
          <h4>Delete</h4>
        </div>

        <hr />

        {userList.length > 0 && 
          userList.map((user, idx) => (
            <div key={idx} className='user'>
              <h4>{user.user_id}</h4>
              <h4>{user.user_name}</h4>
              {/* <h4>{user.email}</h4> */}
              <button
                onClick={() => navigate('/portal/user', { state: user.user_id })}
                className='btn-edit'
              >
                Edit
              </button>
              <button
                onClick={() => handledeleteUserById(user.user_id)}
                className='btn'
              >
                Delete
              </button>
            </div>
          ))}
      </div>
    </>
  )
}

export default UsersList