import React, { useEffect, useState } from "react";
import "./addVoucher.css";
import { Input } from "antd";
import { toast } from "react-toastify";
import { createVoucher, getOneVoucherById, updateVoucher } from "../../services/VoucherServices";
import { useLocation } from "react-router-dom";

const AddVoucher = () => {
  const [formValues, setFormValues] = useState({
    voucher_type: "receipt",
    user_id: "",
    start_date: "",
    start_time: "",
    amount: "",
    description: ""  
  });

  const location = useLocation(); 

  useEffect(()=>{
    // console.log(location.state)
    if(location.state){
      getOneVoucherById(location.state).then((response)=>{
        console.log(response)
        setFormValues({
          voucher_type: response.voucher_type,
          user_id: response.user_id,
          start_date: response.start_date,
          start_time: response.start_time,
          amount: response.amount,
          pamount:  response.amount,
          description: response.description  
        })
      })
    }
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const saveSetting = async () => {
    try {
      const result = await createVoucher(formValues);
      if (result) {
        alert("Saved Succesfully");
      }
    } catch (error) {
        console.log(error);
        alert("Server Error!");
    }
  };

  const updateData = async () => {
    try {
      const result = await updateVoucher({...formValues,id:location.state});
      if (result) {
        alert("Updated Succesfully");
      }
    } catch (error) {
        console.log(error);
        alert("Server Error!");
    }
  };

  return (
    <div className="user-container">
      <div className="heading-div">
        <h3 className="featureTitle">
          <b>{location.state ? "Voucher" : "Add New Voucher"}</b>
        </h3>
      </div>
      <div className="form-main">
        <div className="form-left">
          {" "}
          <div className="form-group">
            <label className="my-label" htmlFor="voucher_type">
              Voucher Type :
            </label>
            <select
              className="form-control"
              id="voucher_type"
              name="voucher_type"
              value={formValues.voucher_type}
              onChange={handleChange}
              // contentEditable
            >
              <option value="receipt">Receipt Voucher</option>
              <option value="payment">Payment Voucher</option>
              {/* <option value="booking">Booking Voucher</option> */}
            </select>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="userid">
              User Id :
            </label>
            <Input
              type="text"
              className="form-control"
              id="user_id"
              name="user_id"
              value={formValues.user_id}
              onChange={handleChange}
              placeholder="User Id"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="start_date">
              Date :
            </label>
            <Input
              type="date"
              className="form-control"
              id="start_date"
              name="start_date"
              value={formValues.start_date}
              onChange={handleChange}
              placeholder="Date"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="start_time">
              Time :
            </label>
            <Input
              type="time"
              className="form-control"
              id="start_time"
              name="start_time"
              value={formValues.start_time}
              onChange={handleChange}
              placeholder="Time"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="amount">
              Amount :
            </label>
            <Input
              type="text"
              className="form-control"
              id="amount"
              name="amount"
              value={formValues.amount}
              onChange={handleChange}
              placeholder="Amount"
              required
            ></Input>
          </div>
          <div className="form-group">
            <label className="my-label" htmlFor="balance">
              Description :
            </label>
            <Input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              placeholder="Description"
              required
            ></Input>
          </div>
          <div className="form-btn-div">
            {!location.state ? (
              <button className="save-btn" onClick={saveSetting}>
                Save
              </button>
            ):(
              <button className="save-btn" onClick={updateData}>
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVoucher;
